/* global workbox, importScripts */
importScripts('https://storage.googleapis.com/workbox-cdn/releases/4.3.1/workbox-sw.js');
if (workbox) {
  console.log(`Yay! Workbox is loaded ?`);
  workbox.core.skipWaiting();
  workbox.precaching.precacheAndRoute(self.__WB_MANIFEST);
  workbox.routing.registerRoute(
    /https:\/\/images\.mlssoccer\.com/,
    new workbox.strategies.NetworkFirst({
      cacheName: 'cloudinary-cache',
      plugins: [
        new workbox.expiration.Plugin({
          // Cache 50 items
          maxEntries: 50,
          purgeOnQuotaError: true,
        }),
      ],
    })
  );
  workbox.routing.registerRoute(
    // Cache news pages
    /\/news\//,
    new workbox.strategies.NetworkFirst({
      // Use a custom cache name.
      cacheName: 'news-cache',
      plugins: [
        new workbox.expiration.Plugin({
          // Cache 20 items
          maxEntries: 20,
          purgeOnQuotaError: true,
        }),
      ],
    })
  );
  workbox.routing.registerRoute(
    // Cache photos pages
    /\/photos\//,
    new workbox.strategies.NetworkFirst({
      // Use a custom cache name.
      cacheName: 'photos-cache',
      plugins: [
        new workbox.expiration.Plugin({
          // Cache 10 items
          maxEntries: 10,
          purgeOnQuotaError: true,
        }),
      ],
    })
  );
  workbox.routing.registerRoute(
    // Cache videos pages
    /\/videos\//,
    new workbox.strategies.StaleWhileRevalidate({
      // Use a custom cache name.
      cacheName: 'videos-cache',
      plugins: [
        new workbox.expiration.Plugin({
          // Cache 10 items
          maxEntries: 10,
          purgeOnQuotaError: true,
        }),
      ],
    })
  );
  workbox.routing.registerRoute(
    // Cache addon files.
    /\/addon\//,
    // Use cache but update in the background.
    new workbox.strategies.NetworkFirst({
      // Use a custom cache name.
      cacheName: 'addon-cache',
    })
  );
  workbox.routing.registerRoute(
    /\.(?:woff|woff2)$/,
    new workbox.strategies.StaleWhileRevalidate({
      // Use a custom cache name.
      cacheName: 'fonts',
      plugins: [
        new workbox.expiration.Plugin({
          // Cache for 365 days
          maxAgeSeconds: 60 * 60 * 24 * 365,
        }),
      ],
    })
  );
  workbox.routing.registerRoute(
    /\.(?:png|jpg|jpeg|svg,gif,ico)$/,
    new workbox.strategies.StaleWhileRevalidate({
      // Use a custom cache name.
      cacheName: 'images',
      plugins: [
        new workbox.expiration.Plugin({
          // Only cache 20 images.
          maxEntries: 20,
        }),
      ],
    })
  );
  workbox.routing.registerRoute(
    // Cache CSS files.
    /\/assets\//,
    // Use cache but update in the background.
    new workbox.strategies.NetworkFirst({
      // Use a custom cache name.
      cacheName: 'assets-cache',
    })
  );
  if (workbox.precaching && workbox.precaching.cleanupOutdatedCaches) {
    workbox.precaching.cleanupOutdatedCaches();
  }
} else {
  console.log(`Boo! Workbox didn't load ?`);
}
